/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-primary-color {
  background-color: #3F51B5;
}

.custom-height {
  height: calc(100vh - 100px)
}

.mat-simple-snackbar {
  font-size: 1.2em;
  color: white;
}


.mat-mdc-snack-bar-container {
  &.snackbar-error {
    --mdc-snackbar-container-color: red;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mat-mdc-snack-bar-container {
  &.snackbar-success {
    --mdc-snackbar-container-color: green;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.snackbar-info {
  background-color: blue;
}

::-webkit-scrollbar {
  width: 4px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: rgb(3, 3, 59);
  border-radius: 10px;
}

td , th {
  white-space: normal;
  word-wrap: break-word;
  max-width: 200px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none; 
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.mat-mdc-snack-bar-container.success-snackbar {
  --mdc-snackbar-container-color: #298d2c !important; /* Green color */
  color: #fff !important;
}

.mat-mdc-snack-bar-container.error-snackbar {
  --mdc-snackbar-container-color: #f44336 !important; /* Red color */
  color: #fff !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
